<template>
  <div class="page">
    <Backlink :backlink="nextlink" />
      <div class="content">
        <div class="content__container">
          <TextBlock :id="4" />
          <router-link :to="nextlink" class="button button__form">{{ "continue" | localize }}</router-link>
        </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import TextBlock from '@/components/Text.vue'

export default {
  name: 'BankPermission',
  components: { Backlink, TextBlock },
  props: {
    nextlink: { type: [String, Object], default: '/' },
  },
  created () {
    if (this.nextlink.params) this.nextlink.params.approved = true
  }
}
</script>
